<template>
  <div>
    <br v-if="isMobile">
    <div class="container-fluid">   
       <b-row class="justify-content-center">
         <b-col cols="11" md="8">             
            <p class="text-center text-white" style="font-size:18px;">Elije las letras correctas completa la palabra y ¡suma más puntos!</p>
            <div class="text-center" id="countdown2" >
                <h2 style="cursor:pointer; font-size:40px; font-family:Saira Stencil One, cursive; color:#5ab71a;"  id="countdown2">
                    <img src="elementos/reloj.png" class="rounded" alt="Image" :style="!isMobile ? 'width:4%; margin-top:-15px;' : 'width:11%; margin-top:-15px;'"> {{ countdown }}
                </h2>
            </div>
            <div  id="fondoPista" > 
              <b-card  style="background-color:rgba(0, 0, 0, 0); border-color:#187abe;" class="mt-5">
               <b-card-text class="text-center">
                <br>
                <template v-if="!loader"> 
                    <b-row class="justify-content-center" v-if="correcto == 0">
                        <b-col cols="11" sm="6" md="6">
                            <br v-if="isMobile">
                            <img class="img-fluid" :src="imagePath()" alt="" :style="isMobile ? 'width: 90%;' : 'width: 60%;'">
                            <br><br>
                            <h3 class="displayed-word" style="color:#fff;">{{displayWord()}}</h3>
                            <!-- <p class="text-white">Intentos disponibles: {{remainingAttempts}}</p> -->
                        </b-col>
                        <b-col cols="12" sm="6" md="4" class="mt-2">
                            <b-row class="justify-content-center">
                                <!-- <b-col cols="2"></b-col> -->
                                <b-col cols="4" md="3" v-for="letter in letters " :key="letter.letter">
                                    <template v-if="!letter.disabled">
                                        <v-btn color="#5cb617" block  @click="attemptWithLetter(letter.letter)" class="mt-2"  large
                                        style="background-color:rgba(0, 0, 0, 0); color:#5cb617; font-size:28px; border-color:#5cb617; border-style: dotted;">
                                        <template v-if="letter.letter == 'M'"> <img src="elementos/eme.png" class="rounded" alt="Image" :style="!isMobile ? 'width:28%;' : 'width:15%;'"></template>
                                        <template v-else>{{letter.letter}}    </template>                                     
                                        </v-btn>
                                    </template>
                                    <template v-else>
                                    <v-btn color="#187abe" block   class="mt-2"  large
                                        style="background-color:rgba(0, 0, 0, 0); color:#187abe; font-size:28px; border-color:#187abe; border-style: dotted;">
                                        <template v-if="letter.letter == 'M'"> <img src="elementos/eme2.png" class="rounded" alt="Image" :style="!isMobile ? 'width:28%;' : 'width:15%;'"></template>
                                        <template v-else>{{letter.letter}}    </template>  
                                        </v-btn>
                                    </template>
                                
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col cols="1"></b-col>
                    </b-row>
                    <template v-else-if="correcto == 1">
                        <br>
                            <p class="text-white" :style="isMobile ? 'font-size:28px;' : 'font-size:45px;'">
                                <b>¡Felicidades!</b> <br>
                                Cumpliste la misión.
                                <br> ¡Ganaste <span style="color:#187abe;">05 Puntos!</span>
                            </p>
                        <br>
                    </template>
                    <template v-else>
                        <br>
                            <p class="text-white" :style="isMobile ? 'font-size:32px;' : 'font-size:45px;'">
                                <b>Gracias por participar.</b>
                                <br> 
                                Prepárate mejor para la próxima misión 
                            </p>
                        <br>
                    </template>
                    <br>
                </template>
                <template v-else>
                    <center><b-spinner style="color:#fff;" small></b-spinner></center> 
                </template>
               </b-card-text>
              </b-card>         
            </div>
         </b-col>
        </b-row>
    </div>
  </div>
</template>

<script>
const ALPHABET = "ABCDEFGHIJKLMNÑOPQRSTUVWXYZ",
    MAX_ATTEMPTS = 6,
    MASK_CHAR = "_";
import * as moment from 'moment';
export default {
  name: 'juego2',
  data () {
    return {
        letters: {},
        hiddenWord: [],
        remainingAttempts: 0,
        correcto:0,
        countdown: moment.utc(61).format('HH:mm:ss'),
        expires_in: null,
        interval: null,
        Clickinterval: false,
        loader:false,
        isMobile: this.$mobile(),
    }
  },
  mounted(){
    // this.getJuego();
    this.correcto = 0;
    this.resetGame();
     this.countdown = moment.utc(32).format('HH:mm:ss');
     this.expires_in = 1;
     this._setInterval();
  },
  destroyed () {
      clearInterval(this.interval);
  },
  computed: {
    user() {
      return this.$store.getters.getInfoUser;
    },
  },
  methods: {
        getJuego(){
         this.loader = true;
         var url= 'juego/estatus2?email='+ this.user.email;
            this.$api.get(url).then(
                ({data}) => {
                    console.log(data);
                    this.loader = false;
                    this.correcto = data.correcto;
                    if (data.correcto != 0)
                    {
                        clearInterval(this.interval);    
                    }
                    if(data.correcto == 1){
                    this.$confetti.start({
                        particles: [
                            {
                            type: 'rect',
                            },
                            {
                            type: 'circle',
                            },
                        ],
                        defaultColors: [
                            '#5cb617',
                            '#ffffff',
                            '#187abe'
                        ],
                    });
                    setTimeout(() => {
                        this.$confetti.stop();
                    }, 2000);
                    }
                }
            );
        },
            _setInterval: function() {
        // this.mostrarPregunta = 1;
        if(this.Clickinterval==false){
            this.interval = setInterval(() => {
                if (this.expires_in === 0) {
                    clearInterval(this.interval);
                } else {
                    this.expires_in += 1;
                    if(this.expires_in>0){
                        this.countdown = moment.utc(this.expires_in * 1000).subtract(1, 'seconds').format('HH:mm:ss');
                    }
                }
            }, 1000);
            this.Clickinterval = true;
        }
        },
        resetGame() {
            this.resetAttempts();
            this.setupKeys();
            this.chooseWord();
        },
        checkGameStatus() {
            if (this.playerWins()) {
                this.correcto = 1;               
                clearInterval(this.interval);
                this.$confetti.start({
                      particles: [
                          {
                          type: 'rect',
                          },
                          {
                          type: 'circle',
                          },
                      ],
                      defaultColors: [
                          '#5cb617',
                          '#ffffff',
                          '#187abe'
                      ],
                  });
                  setTimeout(() => {
                      this.$confetti.stop();
                  }, 2000);

                //   this.respondeJuego();
            }
            if (this.playerLoses()) {
                this.correcto = 2;
                // this.respondeJuego();
                clearInterval(this.interval);
                this.resetGame();
            }

             
        },
        getUnhiddenWord() {
            let word = "";
            for (const letter of this.hiddenWord) {
                word += letter.letter;
            }
            return word;
        },
        playerWins() {
            // If there's at least a hidden letter, the player hasn't win yet
            for (const letter of this.hiddenWord) {
                if (letter.hidden) {
                    return false;
                }
            }
            return true;
        },
        playerLoses() {
            return this.remainingAttempts <= 0;
        },
        imagePath() {
            return `ahorcado/Ahorcado-${MAX_ATTEMPTS - this.remainingAttempts}.png`;
        },
        resetAttempts() {
            this.remainingAttempts = MAX_ATTEMPTS;
        },
        chooseWord() {
            // Get words stored in localstorage
            const words = ['smartphone','Equipo','Mision'];
            // Choose random
            let word = words[Math.floor(Math.random() * words.length)];
            this.prepareWord(word);
        },
        prepareWord(word) {
            word = word.toUpperCase();
            const hiddenWord = [];
            for (const letter of word) {
                hiddenWord.push({
                    letter,
                    hidden: true,
                });
            }
            this.hiddenWord = hiddenWord;
        },
        displayWord() {
            let displayedWord = "";
            for (const letter of this.hiddenWord) {
                if (letter.hidden) {
                   displayedWord += MASK_CHAR;
                } else {
                   displayedWord += letter.letter;
                }
               displayedWord += " ";
            }
             return displayedWord;
        },
        setupKeys() {
            // We make a dictionary from the letters
            for (const letter of ALPHABET) {
                this.$set(this.letters, letter, { letter, disabled: false});
                // Vue.set(this.letters, letter, {
                //     letter,
                //     disabled: false, // We disable it when the user clicks on it
                // });
            }
        },
        letterExistsInWord(searchedLetter) {
            for (const letter of this.hiddenWord) {
                if (letter.letter === searchedLetter) {
                    return true;
                }
            }
            return false;
        },
        discoverLetter(letter) {
            for (const index in this.hiddenWord) {
                if (this.hiddenWord[index].letter === letter) {
                    this.hiddenWord[index].hidden = false;
                }
            }
        },
        attemptWithLetter(letter) {
            this.$set(this.letters[letter], "disabled", true);
            // Vue.set(this.letters[letter], "disabled", true);
            if (!this.letterExistsInWord(letter)) {
                this.remainingAttempts -= 1;
            } else {
                this.discoverLetter(letter);
            }
            this.checkGameStatus();
        },
        respondeJuego(){     
            this.$api.post('juego/store', {
                    mrt: this.user.mrt,
                    idpdv: this.user.id_pdv,
                    email: this.user.email,
                    seccion: 'Juego',
                    puntos: this.correcto == 1 ? 5 : 0 ,
                    perfil: this.user.perfil,
                    respuesta: 'Ahorcado',
                    tiempo: this.countdown
                }).then(response => {
                        console.log(response);
                        this.loader = false;  
                        clearInterval(this.interval);
                        this.resetGame();
                    })
                    .catch(error => {
                        console.log(error);
                        this.loader = false;
                    });
            },
    }
}
</script>
<style>
 #fondoPista{
     background-image: url('https://www.inquebrantables2022.com.mx/elementos/ahorcado.jpeg');
     width: 100%!important;
     
    }
</style>